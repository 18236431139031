<template>
  <div class="compile-container">
    <div class="sidebar">
      <div class="section-button general">
        <img src="@/assets/images/person-icon.png" class="icon">Stakeholders</div>
      <div
        :class="'central section-button ' + (currentSection == key ? 'active' : '')"
        v-for="(item, key) in sections"
        v-bind:key="key"
        @click="editSection(key)">
        {{ item.name }}
      </div>
     
      <div class="section-button general" @click="gotoGeneral" v-if="general.length">
        <img src="@/assets/images/icona-general.png" alt="" class="icon">General</div>
    </div>
    <div class="main-section" v-if="isGeneral">
      <div class="row head">
        <div class="col-8">QUESTION</div>
        <div class="col-4">SPACE FOR NOTES</div>
      </div>
      <div class="row quiz" v-for="(item, key) in general" v-bind:key="key">
        <div class="col-8">
          <div :class="'response ' + (general[key].active ? 'active' : '')" @click="toggleGeneral(key)"></div>
          {{item.text}}
        </div>
        <div class="col-4">
          <textarea name="" id="" cols="1" rows="1" v-model="general[key].note"></textarea>
        </div>
      </div>
      <div class="text-right" v-if="!$route.params.userId && !isAdmin">
        <b-button variant="success" class="my-4 mx-4" @click="saveQuestionnaire(false)">Save</b-button>
        <b-button variant="success" class="my-4 send-to-admin" @click="saveQuestionnaire(true)">Send to admin</b-button>
      </div>

      <div class="text-right" v-if="$route.params.userId && isAdmin">
        <b-button variant="success" class="my-4 mx-4" @click="saveQuestionnaire(true)">Save</b-button>
        <b-button variant="success" class="my-4 send-to-admin" @click="saveQuestionnaire(false)">Send back to user</b-button>
      </div>
    </div>
    <div class="main-section" v-if="!isGeneral">
      <h2 class="">Questionnaire</h2>
      <b-card>
        <div v-if="currentSection === undefined">
          <h4>How to use this tool?</h4>
          <p>Move among the different stakeholders clicking on the vertical tabs on the left. For each stakeholder you will find some social topics with some questions: answer all of them. Click on the Social Topic to change section. Rollover on the “info” icon to read some further instructions (e.g. which department or stakeholders is suggested to be interviewed to get the answers). Comment on the “note” box to make us now some impressions, assumptions, doubts. Mark the “low data quality” check box if you do not feel confident with your answers. You can Save the questionnaire and fill it in at a later stage.  When the questionnaire is completed, Send it to Admin!</p>
          <p>Last but not least... <b>thank you very much for the collaboration!</b></p>
        </div>
        <div v-if="currentSection !== undefined">
          <div class="section-tabs">
            <div
              :class="'tab-button ' + (currentSocialTopic == key ? 'active' : '')"
              v-for="(item, key) in sections[currentSection].socialTopics"
              @click="editSocialTopic(key)"
              v-bind:key="key">
              {{ item.name }}
            </div>
          </div>

          <div v-if="currentSocialTopic !== undefined">
            <div class="social-head">
              <div class="social-question">QUESTION</div>
              <div class="social-answers">ANSWER</div>
              <div class="social-notes">SPACE FOR NOTES</div>
              <div class="social-low">LOW DATA QUALITY?</div>
            </div>
            <div
              class="social-row"
              v-for="(item, key) in sections[currentSection].socialTopics[currentSocialTopic].questions"
              v-bind:key="key"
              >
              <div class="social-question">
                {{item.question}}

                <div class="info-icon" v-if="item.info.length" v-tooltip="item.info">
                 <img src="@/assets/images/info-icon.png" :title="item.info">
                </div>
              </div>
              <div class="social-answers">
                <div
                  class="answer"
                  v-if="item.a.length"
                  >
                  <div
                    :class="'response ' + (hasAnswerActive(key, 'a') ? 'active' : '')"
                    @click="setAnswer(key, 'a')"
                    ></div>
                  <div class="response-text">{{item.a}}</div>
                </div>
                <div
                  class="answer"
                  v-if="item.b.length"
                  >
                  <div
                    :class="'response ' + (hasAnswerActive(key, 'b') ? 'active' : '')"
                    @click="setAnswer(key, 'b')"
                    ></div>
                  <div class="response-text">{{item.b}}</div>
                </div>
                <div
                  class="answer"
                  v-if="item.c.length"
                  >
                  <div
                    :class="'response ' + (hasAnswerActive(key, 'c') ? 'active' : '')"
                    @click="setAnswer(key, 'c')"
                    ></div>
                  <div class="response-text">{{item.c}}</div>
                </div>
                <div
                  class="answer"
                  v-if="item.d.length"
                  >
                  <div
                    :class="'response ' + (hasAnswerActive(key, 'd') ? 'active' : '')"
                    @click="setAnswer(key, 'd')"
                    ></div>
                  <div class="response-text">{{item.d}}</div>
                </div>
                <div
                  class="answer"
                  v-if="item.e.length"
                  >
                  <div
                    :class="'response ' + (hasAnswerActive(key, 'e') ? 'active' : '')"
                    @click="setAnswer(key, 'e')"
                    ></div>
                  <div class="response-text">{{item.e}}</div>
                </div>
              </div>
              <div class="social-notes">
                <textarea v-model="answers[currentSection].socialTopics[currentSocialTopic].questions[key].usernotes"></textarea>
              </div>
              <div class="social-low">
                <div
                  :class="'response ' + (answers[currentSection].socialTopics[currentSocialTopic].questions[key].low ? 'active' : '')"
                  @click="setLow(key)"></div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <div class="text-right" v-if="!$route.params.userId && !isAdmin">
        <b-button variant="success" class="my-4 mx-4" @click="saveQuestionnaire(false)">Save</b-button>
        <b-button variant="success" class="my-4 send-to-admin" @click="saveQuestionnaire(true)">Send to admin</b-button>
      </div>

      <div class="text-right" v-if="$route.params.userId && isAdmin">
        <b-button variant="success" class="my-4 mx-4" @click="saveQuestionnaire(true)">Save</b-button>
        <b-button variant="success" class="my-4 send-to-admin" @click="saveQuestionnaire(false)">Send back to user</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Questionnaire',

  data: function () {
    return {
      project: {
        name: '',
        id: ''
      },
      isGeneral: false,
      general: [],
      sections: [],
      answers: [],
      newSection: '',
      newSocialTopic: '',
      currentSection: undefined,
      currentSocialTopic: undefined,
      userId: false,
      userName: false,
      projectId: false,
      isAdmin: false
    }
  },

  mounted () {
    const projectId = this.$route.params.projectId
                    ? this.$route.params.projectId
                    : this.$store.state.user.projects[0].id

    const userId = this.$route.params.userId
                    ? this.$route.params.userId
                    : this.$store.state.user.uid

    this.userId = userId
    this.projectId = projectId

    this.fetchProject(projectId, userId)
  },

  methods: {
    async fetchProject (id, userId) {
      let project = await this.$firestore.collection('projects')
        .doc(id)
        .get()

      this.project = {
        ...project.data(),
        id: project.id
      }

      let questionnaire = await this.$firestore.collection('questionnaires')
        .where('project', '==', id)
        .get()

      let responses = await this.$firestore.collection('responses')
        .where('projectId', '==', id)
        .where('userId', '==', userId)
        .get()

      if (questionnaire.size) {
        questionnaire.forEach(doc => {
          this.sections = doc.data().sections
          this.answers = doc.data().sections
          this.general = doc.data().general
        });
      }

      if (responses.size) {

        responses.forEach(doc => {
          this.answers = doc.data().sections
          this.general = doc.data().general
          this.userName = doc.data().userName
          this.isAdmin = doc.data().admin

          console.log(JSON.stringify(doc.data()))
          console.log(doc.data())
        });
      }

      if (this.sections.length != this.answers.length) {
        this.answers = this.sections
      }
    },

    addSection (evt) {
      if (evt.code == 'Enter') {
        this.sections.push({
          name: this.newSection,
          socialTopics: []
        })
        this.newSection = ''
      }
    },

    addSocialTopic (evt) {
      if (evt.code == 'Enter') {
        this.sections[this.currentSection].socialTopics.push({
          name: this.newSocialTopic,
          questions: []
        })
        this.newSocialTopic = ''
      }
    },

    gotoGeneral () {
      this.isGeneral = true
    },

    editSection (id) {
      this.isGeneral = false
      this.currentSection = id
      this.currentSocialTopic = undefined
      if (this.sections[this.currentSection].socialTopics.length) {
        this.currentSocialTopic = 0
      }
    },

    editSocialTopic (id) {
      this.currentSocialTopic = id
    },

    validateToAdmin () {
      for (let section in this.answers) {
        for (let topic in this.answers[section].socialTopics) {
          for (let question in this.answers[section].socialTopics[topic].questions) {
            if (!this.answers[section].socialTopics[topic].questions[question].active) {
              return false
            }
          }
        }
      }

      return true
    },

    async saveQuestionnaire (toAdmin) {
      if (toAdmin && !this.validateToAdmin()) {
        this.$swal('Compile all questions before send');
        return false;
      }

      let responses = await this.$firestore.collection('responses')
        .where('projectId', '==', this.projectId)
        .where('userId', '==', this.userId)
        .get()

      if (responses.size) {
        responses.forEach(async (doc) => {
          await this.$firestore.collection('responses').doc(doc.id).delete()
        });
      }

      await this.$firestore.collection('responses').add({
        sections: this.answers,
        general: this.general,
        projectId: this.projectId,
        projectName: this.project.name,
        admin: toAdmin,
        userId: this.userId,
        userName: this.$store.state.user.name
      })

      this.isAdmin = toAdmin

      if(this.isAdmin){
        let resp = await this.$axios.get('/users')
        let users = resp.data.users
        let myCompany = ''

        users.map(user => {
          if(user.uid === this.$store.state.user.uid){
            myCompany = user.customClaims.company
          }
        })

        let toSend = {
          user: this.$store.state.user.name,
          company: myCompany,
          project: this.project.name
        }
        this.$axios.post('/sendMail', toSend)
          .catch(e => console.log(e))
      }

      this.$swal('Questionnaire correctly saved')
    },

    setAnswer (key, answer) {
      this.answers[this.currentSection].socialTopics[this.currentSocialTopic].questions[key].active = answer
      this.$forceUpdate()
    },

    toggleGeneral (key) {
      this.general[key].active = !this.general[key].active
      this.$forceUpdate()
    },

    setLow (key) {
      let currentLow = this.answers[this.currentSection].socialTopics[this.currentSocialTopic].questions[key].low
      this.answers[this.currentSection].socialTopics[this.currentSocialTopic].questions[key].low = currentLow ? false : true
      this.$forceUpdate()
    },

    hasAnswer (key) {
      return this.answers &&
        this.answers[this.currentSection] &&
        this.answers[this.currentSection].socialTopics &&
        this.answers[this.currentSection].socialTopics[this.currentSocialTopic] &&
        this.answers.[this.currentSection].socialTopics[this.currentSocialTopic].questions &&
        this.answers.[this.currentSection].socialTopics[this.currentSocialTopic].questions[key]
    },

    hasAnswerActive (key, value) {
      return  this.hasAnswer(key) &&
        this.answers.[this.currentSection].socialTopics[this.currentSocialTopic].questions[key].active == value
    }
  }
}
</script>

<style>
.social-button {
  display: inline-block;
  height: 35px;
  line-height: 35px;
  padding: 0 5px;
  border: 1px solid #dadada;
  margin-right: 10px;
  vertical-align: middle;
}

.social-button.active {
  border: 1px solid green;
  background-color: rgba(0, 255, 0, 0.2);
}

textarea {
  width: 100%;
  height: 60px;
  font-size: 12px;
  line-height: 20px;
}

.container.my-5 {
  width: 100% !important;
  max-width: 100% !important;
  padding-left: 0 !important;
  margin: 0 !important;
}

.container.my-5 .row {
  margin: 0 !important;
}

.sidebar {
  width: 300px;
  float: left;
  display: block;
  height: 100vh;
  background-color: #2d8f46;
  position: relative;
  z-index: 2;
}

.compile-container {
  display: flex;
  width: 100%;
}

.central.section-button {
  padding-left:65px ;
}
.section-button {
  width: 300px;
  height: 60px;
  line-height: 60px;
  padding-left: 30px;
  color: #fff;
  text-align: left;
  -webkit-box-shadow: 0px 3px 5px 1px rgba(50,50,50,0.44);
  box-shadow: 0px 3px 5px 1px rgba(50,50,50,0.44);
  cursor: pointer;
}

.section-button.active {
  font-weight: bold;
}

.section-button.general {
  background-color: #136127;
  text-transform: uppercase;
}

.main-section {
  width: 950px;
  text-align: left;
  margin-left: 60px;
  margin-top: 20px;
}

.section-tabs {
  width: 100%;
  min-height: 40px;
}

.tab-button {
  display: inline-block;
  border-radius: 18px 18px 0 0;
  background-color: #ccc;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  min-width: 150px;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  padding: 0 5px;
  font-size: 13px;
  margin-top: 5px;
  margin-right: 5px;
}

.tab-button.active {
  background-color: #2d8f46;
}

.social-head, .social-row {
  width: 100%;
  background-color: #2d8f46;
  height: 50px;
  line-height: 50px;
  color: #fff;
  display: flex;
}

.social-row {
  height: auto;
  min-height: 50px;
  line-height: auto;
  color: #424242;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-top: 20px;
}

.social-question {
  width: 300px;
  padding-left: 30px;
  padding-right: 20px;
  font-size: 13px;
}

.social-row .social-question {
  line-height: 140%;
}

.social-answers {
  width: 250px;
  font-size: 13px;
  padding-right: 10px;
}

.social-notes {
  width: 200px;
  font-size: 13px;
}

.social-low {
  width: 160px;
  font-size: 13px;
}

.social-head > div {
  font-weight: bold;
}

.response {
  margin: 0 10px;
  width: 12px;
  height: 12px;
  background-color: #b8b8b8;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
}

.response.active {
  background-color: #2d8f46;
}

.social-row .social-low {
  text-align: center;
}

.social-answers .response {
  margin-right: 10px;
  vertical-align: middle;
  float: left;
}

.answer:after {
  display: table;
  content: '';
  clear: both;
  margin-bottom: 10px;
}

.answer {
  line-height: initial;
  min-height: 20px;
  margin-bottom: 15px;
}

.info-icon {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  font-size: 18px;
  vertical-align: middle;
}

.info-icon img {
  width: 17px;
}

.section-button img {
  width: 10px;
}

.send-to-admin {
  background-color: #136127;
}

.response-text {
  float: left;
  width: 200px;
}
.icon {
  display: inline;
  margin: 0 20px 0 0;
  transform: translateY(-12%);
}

.row.head {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 padding: 20px ;
  font-weight: 600;
  font-size: 11px;
  background-color: #cccc;
  }
.row.quiz {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  background-color: #ffffff;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

</style>

